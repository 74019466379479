import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";

const VideoUrlInput = ({
  label = "Video",
  videoUrlKey,
  videoUrl: _videoUrl,
  durationKey,
  duration: _duration,
}) => {
  const [videoUrl, setVideoUrl] = useState(_videoUrl);
  const [duration, setDuration] = useState(_duration || 0);
  const [height, setHeight] = useState("0");
  const [error, setError] = useState(false);

  useEffect(() => setVideoUrl(_videoUrl), [_videoUrl]);
  useEffect(() => setDuration(_duration || 0), [_duration]);

  return (
    <>
      <div className="row">
        <div className="col-md-8">
          <div className="form-group">
            <label htmlFor="video_url_input">{label}</label>
            <input
              placeholder="url"
              className={`form-control string url optional ${
                error ? "is-invalid" : "is-valid"
              }`}
              type="url"
              value={videoUrl || ""}
              name={videoUrlKey}
              id="video_url_input"
              onChange={(e) => {
                setDuration(0);
                setVideoUrl(e.target.value);
                if (!e.target.value) {
                  setHeight("0");
                }
              }}
            />
            {ReactPlayer.canPlay(videoUrl) && (
              <ReactPlayer
                className="my-1"
                url={videoUrl}
                onReady={() => {
                  setHeight("5rem");
                  setError(false);
                }}
                onError={() => {
                  setHeight("0");
                  setError(true);
                }}
                height={height}
                width="8rem"
                onDuration={(v) => v > 0 && setDuration(v)}
              />
            )}
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="video_duration">Duration</label>
            <div className="input-group">
              <input
                value={duration}
                type="number"
                className="form-control"
                onChange={(e) => setDuration(e.target.value)}
                id="video_duration"
                name={durationKey}
              />
              <div className="input-group-append">
                <span className="input-group-text">seconds</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoUrlInput;
