import React, { createElement, Fragment, useEffect, useRef } from "react";
import { createRoot } from "react-dom/client";
import { autocomplete } from "@algolia/autocomplete-js";
import { createAlgoliaInsightsPlugin } from "@algolia/autocomplete-plugin-algolia-insights";
import { createQuerySuggestionsPlugin } from "@algolia/autocomplete-plugin-query-suggestions";
import insightsClient from "search-insights";

const Autocomplete = ({ searchClient, indices, appId, appKey, ...props }) => {
  const containerRef = useRef(null);
  const panelRootRef = useRef(null);
  const rootRef = useRef(null);

  useEffect(() => {
    insightsClient("init", { appId, apiKey: appKey });
  }, [appId, appKey]);

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const algoliaInsightsPlugin = createAlgoliaInsightsPlugin({
      insightsClient,
    });

    const querySuggestionsPlugin = createQuerySuggestionsPlugin({
      searchClient,
      indexName: "query_suggestions",
    });

    const search = autocomplete({
      autoFocus: true,
      container: containerRef.current,
      renderer: { createElement, Fragment, render: () => {} },
      render({ children }, root) {
        if (!panelRootRef.current || rootRef.current !== root) {
          rootRef.current = root;

          panelRootRef.current?.unmount();
          panelRootRef.current = createRoot(root);
        }

        panelRootRef.current.render(children);
      },
      ...props,
      plugins: [algoliaInsightsPlugin, querySuggestionsPlugin],
    });

    search.update();

    return () => search.destroy();
  }, [props, indices, searchClient, insightsClient]);

  return <div ref={containerRef} />;
};

export default Autocomplete;
