/**
This implementation is not complete, since we are going to use select2 UI for now
We can continue this later if we want to have a more custom UI for topic selection
What's left:
 - Removing selected topic by clicking [x] on the pill
 - Implement keyboard up/down and enter to select the topic while searching (keeping focus on the search field)
*/

import React, {useRef, useState} from "react";

function TopicCheckbox({ indent = false, topic, selected, onTopicClicked }) {
  return (
    <div className={indent ? 'pl-4' : ''}>
      <input id={`topicCb${topic.id}`} type="checkbox" name="_topicPlaceholder" value={topic.id} checked={ selected[topic.id] } onChange={onTopicClicked} />&nbsp;
      <label className="mb-0 cursor-pointer w-75" htmlFor={`topicCb${topic.id}`}>{topic.title}</label>
    </div>
  );
}

function SubTopics({ subTopics, selected, onTopicClicked }) {
  return (
    subTopics.map(topic =>
      <TopicCheckbox key={`cb${topic.id}`} { ...{ topic, indent: true, selected, onTopicClicked } }></TopicCheckbox>
    )
  );
}

function TopicsGroup({ filter = "", header, topics, selected, onTopicClicked }) {
  let filteredTopics = [];
  topics.forEach(topic => {
    topic.filteredSubTopics = topic.subTopics.filter(st => st.title.match(new RegExp(filter, 'i')));
    if (topic.filteredSubTopics.length > 0 || topic.title.match(new RegExp(filter, 'i')))
      filteredTopics.push(topic);
  });
  return (
    <>
      { filteredTopics.length ? <div className="font-weight-bold">{header}</div> : "" }
      {
        filteredTopics.map(topic => {
            return (<div className="py-1" key={`topic${topic.id}`}>
              { topic.hide ? "" : <TopicCheckbox { ...{ topic, selected, onTopicClicked } }></TopicCheckbox> }
              <SubTopics { ...{ subTopics: topic.filteredSubTopics, selected, onTopicClicked } }></SubTopics>
            </div>);
          }
        )
      }
    </>
  );
}

const getSelectedTopicList = (topicsByCat, userSelected) => {
  let list = [];
  Object.keys(topicsByCat).map(key => {
    topicsByCat[key].forEach(topic => {
      if (userSelected[topic.id])
        list.push(topic);
      topic.subTopics.forEach(stopic => {
        if (userSelected[stopic.id])
          list.push(stopic);
      });
    });
  });
  return list;
};

function SelectTopics({ inputName = "topics", height = "20em", topicsByCat, selected }) {
  let searchInput = useRef();
  let [filter, setFilter] = useState("");
  let [userSelected, setUserSelected] = useState(selected);

  const clearFilter = (e) => {
    e.preventDefault();
    searchInput.current.value = "";
    updateFilter();
  };
  const updateFilter = () => {
    setFilter(searchInput.current.value.trim());
  };
  const onTopicClicked = (e) => {
    setUserSelected({
      ...userSelected,
      [e.target.value]: e.target.checked
    });
  };

  return (
    <>
      <div>Associated Topics</div>
      <input type="hidden" name={inputName} value={Object.entries(userSelected).filter(e => e[1]).map(e => e[0]).join(',')}/>
      <div className="py-2">
      {
        getSelectedTopicList(topicsByCat, userSelected).map(topic =>
          <span className="badge badge-outline-primary mr-2" key={topic.id}>{topic.title} <i className="fas fa-square-xmark cursor-pointer"></i></span>
        )
      }
      </div>
      <div className="row">
        <div className="col col-md-6">
          <input ref={searchInput} type="text" placeholder="Search Topics" className="form-control mb-2" onKeyUp={updateFilter} />
        </div>
        <div className="col col-md-2"><button className="btn btn-secondary" onClick={clearFilter}>Clear Filter</button></div>
      </div>
      <div className="border rounded p-2" style={ { 'max-height': height, overflowX: 'hidden', overflowY: 'scroll' } }>
      {
        Object.keys(topicsByCat).map(key =>
          <TopicsGroup key={key} { ...{ topics: topicsByCat[key], header: key, filter: filter, selected: userSelected, onTopicClicked } }></TopicsGroup>
        )
      }
      </div>
    </>
  );
}

export default SelectTopics;
