import React, { useEffect, useState } from "react";
import consumer from "../channels/consumer";
import "../../stylesheets/event-presence";

const EventPresence = ({ event_id, registration_code, has_role }) => {
  const [online, setOnline] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const subscription = consumer.subscriptions.create(
      { channel: "EventChannel", event_id, registration_code },
      {
        connected: () => {
          setLoaded(true);
        },
        disconnected: () => {
          setLoaded(false);
          setOnline([]);
        },
        received: ({ online }) => {
          if (has_role) {
            setOnline(online);
          }
        },
      }
    );

    return () => subscription.unsubscribe();
  }, [event_id, registration_code, has_role]);

  if (!has_role) {
    return null;
  }

  return (
    <div className="presence-container">
      <div
        className="card-header bg-lighter p-2 d-flex justify-content-between cursor-pointer"
        aria-expanded={collapsed ? "false" : "true"}
        onClick={() => setCollapsed(!collapsed)}
      >
        <span className="presence-count">
          Currently watching: {loaded ? online.length : "loading..."}
        </span>
        <div className="collapse-icon" />
      </div>
      <div className={`collapse ${!collapsed ? "show" : ""}`}>
        <div className="text text-dark ml-2 presence-list">
          {online.map((identity, index) => (
            <div key={index} className="presence-item">
              {identity.split("_")[0]}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EventPresence;
