import React, { useState, useEffect } from "react";
import algoliasearch from "algoliasearch/lite";
import { getAlgoliaResults } from "@algolia/autocomplete-preset-algolia";

import Autocomplete from "./search/Autocomplete";
import Result from "./search/Result";
import Turbolinks from "turbolinks";
import { getUrlForHit } from "./search/utils";
import { useCallback } from "react";

function Search({ pageMode = false, query = "", indexFilter = [], indices, appId, appKey, ...props }) {
  const [searchClient, setSearchClient] = useState(
    algoliasearch(appId, appKey)
  );

  useEffect(() => {
    setSearchClient(algoliasearch(appId, appKey));
  }, [appId, appKey]);

  if (appId === "" || appKey === "") {
    return (
      <div className={!pageMode ? "p-2" : ""}>
        Algolia credentials are missing!
      </div>
    );
  }

  const onStateChange = useCallback(({ state: { query }}) => {
    if (pageMode) {
      const queryInputs = document.querySelectorAll('.algolia-query-input');
      queryInputs.forEach(input => input.value = query);
    }
  }, [pageMode]);

  const onSubmit = useCallback(({ state: { query } }) => {
    Turbolinks.visit(`/search?query=${query}`);
  }, []);

  return (
    <Autocomplete
      appId={appId}
      appKey={appKey}
      searchClient={searchClient}
      indices={indices}
      initialState={{ query, isOpen: !!query }}
      detachedMediaQuery="none"
      debug={pageMode} // This forces the panel to be open
      placeholder="Search CAMP"
      getSources={({ query }) => [
        {
          sourceId: "algolia_indices",
          getItemUrl({ item }) {
            return getUrlForHit(item);
          },
          getItems() {
            if (!query) {
              return [];
            }

            return getAlgoliaResults({
              searchClient,
              queries: indices.map((indexName) => ({
                indexName,
                query,
                filters: indexFilter.map(model => `model_name:${model}`).join(" OR "),
                params: {
                  hitsPerPage: 30,
                },
              })),
            });
          },
          templates: {
            item: ({ item, components }) => (
              <Result hit={item} components={components} pageMode={pageMode} />
            ),
            noResults: ({ state: { query } }) => (
              <span>
                No results for <em>{query}</em>.
              </span>
            ),
          },
        },
      ]}
      onStateChange={onStateChange}
      onSubmit={onSubmit}
      {...props}
    />
  );
}

export default Search;
