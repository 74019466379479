import { decode } from "html-entities";
import { isFuture, parseISO } from "date-fns";

export const formatHit = (hit, key) => ({
  ...hit,
  [key]: decode(hit[key]),
  _highlightResult: {
    ...hit._highlightResult,
    [key]: {
      ...hit._highlightResult[key],
      value: decode(hit._highlightResult[key]?.value),
    },
  },
});

export const getUrlForHit = (hit) => {
  const gidParts = hit.objectID.split('/');
  const id = gidParts[gidParts.length - 1];

  switch (hit.model_name) {
    case 'Event':
      return `/events/${id}`;
    case 'Resource':
      return `/resources/${id}`;
    case 'CaseStudy':
      return `/cases/${id}`;
    case 'ForumTopic':
      return `/forum_topics/${id}`;
    case 'Comment':
      return `/comments/${id}`;
    case 'Course':
      return `/courses/${id}`;
    case 'Lesson':
      return `/lessons/${id}`;
    case 'VeevaDoc':
      return `/educator_resources/${id}`;
  }
}

export const getCtaForHit = (hit) => {
  const gidParts = hit.objectID.split('/');
  const id = gidParts[gidParts.length - 1];

  switch (hit.model_name) {
    case 'Event':
      return isFuture(parseISO(hit.start_at)) ? "View Details" : "Watch";
    case 'Resource':
      return "Learn More";
    case 'CaseStudy':
      return "Watch";
    case 'ForumTopic':
      return "View";
    case 'Comment':
      return "View";
    case 'Course':
      return (window.enrolled_courses || []).includes(parseInt(id)) ? "Continue" : "View Details";
    case 'Lesson':
      return "View";
    case 'VeevaDoc':
      return "View";
  }
}
