import React, { useEffect } from "react";
import { format, parseISO } from "date-fns";
import { getUrlForHit, formatHit, getCtaForHit } from "./utils";

const Result = ({ pageMode, hit, components }) => {
  let label;
  switch (hit.model_name) {
    case "CaseStudy":
      label = "Case Presentation";
      break;
    case "ForumTopic":
      label = "Forum Topic";
      break;
    case "Event":
      label = hit.eyebrow_label;
      break;
    case "VeevaDoc":
      label = "Educator Resources";
      break;
    default:
      label = hit.model_name;
      break;
  }

  const titleAttribute = hit.commentable_title ? "commentable_title" : "title";
  const descAttribute = hit.description ? "description" : "content";

  const result = (
    <div>
      <div className="eyebrow text-tertiary">{label}</div>

      <a href={getUrlForHit(hit)} className="h6 hit-title mb-2">
        <components.Snippet
          attribute={titleAttribute}
          hit={formatHit(hit, titleAttribute)}
        />
      </a>

      <div className="hit-description">
        <components.Snippet
          attribute={descAttribute}
          hit={formatHit(hit, descAttribute)}
        />
      </div>

      <div className="pt-1 text-tertiary small">
        {hit.created_at ? (
          <span>{format(parseISO(hit.created_at), "EEEE, LLLL dd, yyyy")}</span>
        ) : null}
      </div>
    </div>
  );

  useEffect(() => {
    // Rerender the sidebar on the left when we are viewing the page mode
    if (pageMode && document.Camp.sidebar) {
      document.Camp.sidebar.updateSticky();
    }
  }, [pageMode]);

  return pageMode ? (
    <div className="text-dark d-md-flex align-items-center hit px-2">
      <div className="mr-md-3 mb-3 mb-md-0">{result}</div>
      <div className="ml-md-auto text-center">
        <a
          href={getUrlForHit(hit)}
          className="btn btn-outline-primary w-100 text-nowrap"
        >
          {getCtaForHit(hit)}
        </a>
      </div>
    </div>
  ) : (
    <a href={getUrlForHit(hit)} className="text-dark d-block hit px-2">
      {result}
    </a>
  );
};

export default Result;
