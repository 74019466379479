import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Editor } from "@tinymce/tinymce-react";

const QuestionInput = ({
  index,
  isLast,
  config,
  validating,
  question,
  answer,
  onChange,
  onDelete,
}) => (
  <Draggable draggableId={`question-${index}`} index={index}>
    {(provided) => (
      <div
        className={`bg-light p-3 border border-muted ${
          !isLast ? "border-bottom-0" : ""
        }`}
        ref={provided.innerRef}
        {...provided.draggableProps}
      >
        <div className="form-group d-flex">
          <input
            type="text"
            placeholder="Question"
            className={`form-control ${
              validating && !question ? "is-invalid" : ""
            }`}
            value={question}
            onChange={(e) => onChange(e.target.value, answer)}
          />
          <div className="btn px-3" {...provided.dragHandleProps}>
            <i className="fas fa-bars" />
          </div>
          <button className="btn px-3" type="button" onClick={onDelete}>
            <i className="fas fa-times" />
          </button>
        </div>
        <div className={`form-group mb-0 ${validating && !answer ? "border border-danger" : ""}`}>
          <Editor
            value={answer}
            onEditorChange={(answer) => onChange(question, answer)}
            init={{
              ...config,
              height: 200,
              placeholder: "Answer",
            }}
          />
        </div>
      </div>
    )}
  </Draggable>
);

const CommunityFaqInput = ({ value: _value, tinymceConfig, selector, validating }) => {
  const [value, setValue] = useState([]);

  useEffect(() => {
    let parsedValue;
    try {
      parsedValue = JSON.parse(_value);
    } catch (e) {
      parsedValue = [];
    }

    if (!parsedValue || parsedValue.length === 0) {
      parsedValue = [
        {
          question: "",
          answer: "",
        },
      ];
    }

    setValue(parsedValue);
  }, [_value]);

  useEffect(() => {
    const field = document.querySelector(selector);
    if (field) {
      const valueWithoutEmpties = value.filter(
        ({ question, answer }) => !!question || !!answer
      );
      field.value = JSON.stringify(valueWithoutEmpties);
    }
  }, [value]);

  const onChange = (question, answer, index) => {
    const newValue = [...value];
    newValue[index].question = question;
    newValue[index].answer = answer;
    setValue(newValue);
  };

  const onReorder = ({ source, destination }) => {
    if (!destination) {
      // dropped outside
      return;
    }

    const newValue = [...value];

    const [store] = newValue.splice(source.index, 1);
    newValue.splice(destination.index, 0, store);

    setValue(newValue);
  };

  const onDelete = (index) => {
    const ok = confirm("Do you want to delete this question from the FAQ ?");
    if (ok) {
      const newValue = [...value];
      newValue.splice(index, 1);
      setValue(newValue);
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={onReorder}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <>
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {value.map(({ question, answer }, index) => (
                  <QuestionInput
                    key={index}
                    index={index}
                    config={tinymceConfig.options}
                    isLast={index === value.length - 1}
                    validating={validating}
                    question={question}
                    answer={answer}
                    onDelete={() => onDelete(index)}
                    onChange={(question, answer) =>
                      onChange(question, answer, index)
                    }
                  />
                ))}
              </div>
              {provided.placeholder}
            </>
          )}
        </Droppable>
      </DragDropContext>
      <button
        className="btn btn-light mt-3 btn-sm"
        type="button"
        onClick={() => setValue([...value, { question: "", answer: "" }])}
      >
        <i className="fas fa-plus mr-2" />
        Add Question
      </button>
    </>
  );
};

export default CommunityFaqInput;
